/*!

 =========================================================
 * Vue Black Dashboard PRO - v1.4.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/vue-black-dashboard-pro
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 * Coded by Creative Tim

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

 // Core

@import "dashboard/custom/functions";
@import "~bootstrap/scss/functions";

@import "dashboard/custom/variables";
@import "~bootstrap/scss/variables";

@import "dashboard/custom/mixins";
@import "~bootstrap/scss/mixins";

 // Bootstrap components

@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";

 // Custom components

 @import "dashboard/custom/reboot.scss";
 @import "dashboard/custom/global.scss";
 @import "dashboard/custom/utilities.scss";
 @import "dashboard/custom/alerts.scss";
 @import "dashboard/custom/avatars.scss";
 @import "dashboard/custom/badge.scss";
 @import "dashboard/custom/buttons.scss";
 @import "dashboard/custom/close.scss";
 @import "dashboard/custom/custom-forms.scss";
 @import "dashboard/custom/dropdown.scss";
 @import "dashboard/custom/footer.scss";
 @import "dashboard/custom/forms.scss";
 @import "dashboard/custom/grid.scss";
 @import "dashboard/custom/icons.scss";
 @import "dashboard/custom/images.scss";
 @import "dashboard/custom/input-group.scss";
 @import "dashboard/custom/info-areas.scss";
 @import "dashboard/custom/modal.scss";
 @import "dashboard/custom/nav.scss";
 @import "dashboard/custom/navbar.scss";
 @import "dashboard/custom/pagination.scss";
 @import "dashboard/custom/tabs.scss";
 @import "dashboard/custom/pills.scss";
 @import "dashboard/custom/popover.scss";
 @import "dashboard/custom/progress.scss";
 @import "dashboard/custom/type.scss";
 @import "dashboard/custom/tables";
 @import "dashboard/custom/checkboxes-radio";
 @import "dashboard/custom/fixed-plugin";
 @import "dashboard/custom/sidebar-and-main-panel.scss";
 @import "dashboard/custom/misc.scss";
 @import "dashboard/custom/rtl.scss";
 @import "dashboard/custom/timeline.scss";

 // Vendor / Plugins

@import "dashboard/custom/vendor/plugin-nouislider.scss";
@import "dashboard/custom/vendor/plugin-fullcalendar.scss";
@import "dashboard/custom/vendor/plugin-sweetalert2.scss";
@import "dashboard/custom/vendor/plugin-bootstrap-switch.scss";
@import "dashboard/custom/vendor/plugin-jasny-fileupload.scss";
@import "dashboard/custom/vendor/plugin-perfect-scrollbar.scss";
@import "dashboard/custom/vendor/plugin-card-wizard.scss";

// Vendor Element UI plugins
@import "dashboard/custom/vendor/element-ui/plugin-tags";
@import "dashboard/custom/vendor/element-ui/plugin-slider";
@import "dashboard/custom/vendor/element-ui/plugin-select";
@import "dashboard/custom/vendor/element-ui/plugin-inputs";
@import "dashboard/custom/vendor/element-ui/plugin-tables";
@import "dashboard/custom/vendor/element-ui/plugin-datepicker";

// example pages and sections

@import "dashboard/custom/example-pages.scss";

// light mode

@import "dashboard/custom/white-content.scss";

// Cards

@import 'dashboard/custom/card';
@import "dashboard/custom/cards/card-chart";
@import "dashboard/custom/cards/card-map";
@import "dashboard/custom/cards/card-user";
@import "dashboard/custom/cards/card-task";
@import "dashboard/custom/cards/card-animation-on-hover";
@import "dashboard/custom/cards/card-collapse";
@import "dashboard/custom/cards/card-contributions";
@import "dashboard/custom/cards/card-lock";
@import "dashboard/custom/cards/card-pricing";
@import "dashboard/custom/cards/card-profile";
@import "dashboard/custom/cards/card-plain";
@import "dashboard/custom/cards/card-register";
@import "dashboard/custom/cards/card-stats";
@import "dashboard/custom/cards/card-subcategories";
@import "dashboard/custom/cards/card-testimonials";
@import "dashboard/custom/cards/card-wizard";
