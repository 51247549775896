
@import "~@/assets/sass/dashboard/custom/variables";

.settings-icon {
  cursor: pointer;
}

.badge-vue {
  background-color: $vue;
}
